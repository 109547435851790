<template>
<v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        PDF creator
      </div>

      <v-spacer></v-spacer>

      <v-btn
        v-bind:href="logoutLink"
        text
        small
      >
        <span class="mr-2">Uitloggen</span>
        <v-icon>mdi-logout-variant</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>

</template>

<script>
import config from '../config';

export default {
  name: 'RouterBase',

  data: () => ({
    logoutLink: `${config.AUTH_URL}logout`,
  }),
};
</script>
