import Vue from 'vue';
import axios from 'axios';
import config from '../config';
import HttpClient from './HttpClient';

export const store = Vue.observable({
  data: {},
});

export const checkUser = async () => {
  const { data } = await axios.get(`${config.AUTH_URL}user`, { withCredentials: true });
  store.data = { ...data };
  if (store.data.access_token) {
    HttpClient.defaults.headers.common.Authorization = `Bearer ${store.data.access_token}`;
  }
  return data;
};

export default store;
