import Vue from 'vue';
import Router from 'vue-router';
import config from '../config';
import User, { checkUser } from '../utils/User';
import Routerbase from './RouterBase';
import ContactList from '../pages/contacts/ContactList';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      components: {
        default: Routerbase,
      },
      children: [
        { path: '', redirect: { name: 'ContactList' } },
        {
          path: 'contactlist', name: 'ContactList', component: ContactList, meta: { title: 'Contacten' },
        },
      ],
      meta: {
        requiresAuth: true,
      },
    },
  ],
  mode: 'history',
});

/**
 * When routes are accessed this beforeEach is executed
 * @param {Object} to Where the next router action will go to
 * @param {Object} from The location where the router action came from
 * @param {Function} next The next action; must always be executed
 */
router.beforeEach(async (to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find((r) => r.meta && r.meta.title);
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  let user = JSON.parse(JSON.stringify(User.data));
  if (!user.access_token) {
    user = await checkUser();
  }
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !user.access_token) {
    window.location = config.AUTH_URL;
  } else {
    next();
  }
});

export default router;
