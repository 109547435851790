<template>
  <v-container fluid>
    <div v-if="lists.length == 0" class="d-flex justify-center mt-5">
      <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
    </div>
    <div v-else>
      <v-autocomplete
          :items="lists"
          solo
          item-text="name"
          placeholder="Selecteer contact lijst"
          return-object
          @change="onChangeList($event)"
        ></v-autocomplete>

      <v-data-iterator
        :items="contacts"
        :search="search"
        :sort-by="sortBy.toLowerCase()"
        :sort-desc="sortDesc"
        :items-per-page="itemsPerPage"
        item-key="id"
        no-data-text="Geen data beschikbaar"
        no-results-text="Geen data beschikbaar"
        hide-default-footer
        single-select
      >
        <template v-slot:header>
          <v-toolbar
            dark
            color="blue darken-3"
            class="mb-1"
          >
            <v-text-field
              v-model="search"
              clearable
              flat
              :disabled="contacts.length == 0"
              solo-inverted
              hide-details
              prepend-inner-icon="mdi-magnify"
              label="Zoeken"
            ></v-text-field>

              <v-spacer></v-spacer>
              <v-select
                v-model="sortBy"
                flat
                solo-inverted
                hide-details
                :disabled="contacts.length == 0"
                :items="keys"
                label="Sorteer op"
              ></v-select>
              <v-btn-toggle
                v-model="sortDesc"
                mandatory
              >
                <v-btn
                  large
                  :disabled="contacts.length == 0"
                  depressed
                  color="blue"
                  :value="false"
                >
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn
                  large
                  :disabled="contacts.length == 0"
                  depressed
                  color="blue"
                  :value="true"
                >
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </v-btn-toggle>

              <v-spacer></v-spacer>

              <v-btn
                  large
                  :disabled="contacts.length == 0"
                  depressed
                  color="blue"
                  class="mr-2"
                  @click="badges()"
                >
                  Naambadges
              </v-btn>
              <v-btn
                  large
                  :disabled="contacts.length == 0"
                  depressed
                  color="blue"
                  @click="nameplates()"
                >
                  Naamboordjes
              </v-btn>

          </v-toolbar>
        </template>

        <template v-slot:default="props">
          <v-row class="mt-4 ml-3 mr-3">
            <v-col md="3" class="text-left">
              <h3>Naam</h3>
            </v-col>
            <v-col md="3" class="text-left">
              <h3>Bedrijf</h3>
            </v-col>
             <v-col md="1" class="text-left">
              <h3>Type&nbsp;deelnemer</h3>
            </v-col>
             <v-col md="1" class="text-left">
              <h3>Laatste&nbsp;wijziging</h3>
            </v-col>
             <v-col md="1" class="text-left">
              <h3>Aangemaakt</h3>
            </v-col>
          </v-row>
          <v-row
            align="center"
            justify="center"
            v-for="item in props.items"
            :key="item.id"
            @click="selectRow(item.id)"
            class="ml-3 mr-3"
            :class = "(selected == item.id)?'rowSelected':'row'"
          >
            <v-col md="3" class="text-left">
              <v-checkbox
                v-model="contactselected"
                :label="item.firstname + ' ' + item.lastname"
                :value="item.id"
              ></v-checkbox>
              <!-- {{ item.firstname }} {{ item.lastname }} -->
            </v-col>
            <v-col md="3" class="text-left">
              {{ item.company }}
            </v-col>
            <v-col md="1" class="text-left">
              {{ item.congres_deelnemer && item.congres_deelnemer == 'true' ? 'Deelnemer' : '' }}
              {{ item.congres_gast && item.congres_gast == 'true' ? 'Gast' : '' }}
              {{ item.congres_organisatie
                  && item.congres_organisatie == 'true' ? 'Organisatie' : '' }}
              {{ item.congres_spreker && item.congres_spreker == 'true' ? 'Spreker' : '' }}
              {{ item.leverancier && item.leverancier == 'true' ? 'Leverancier' : '' }}
            </v-col>
             <v-col md="1" class="text-left">
               {{ item.added.format('llll') }}
            </v-col>
             <v-col md="1" class="text-left">
              {{ item.lastmodifieddate.format('llll') }}
            </v-col>
            <v-col md="3" class="text-right">
              <div v-if="selected == item.id">
                <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Certificate
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item-group>
                  <v-list-item>
                      <v-list-item-title
                      @click="certificate('phit', item.id)">MedPHIT</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title
                      @click="certificate('phit-en', item.id)">MedPHIT - English</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title
                      @click="certificate('ihe', item.id)">IHE</v-list-item-title>
                    </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
                <v-btn small class="mr-2" @click="badges(item.id)">Badge</v-btn>
                <v-btn small @click="nameplates(item.id)">Nameplate</v-btn>
              </div>
            </v-col>
          </v-row>
        </template>

      </v-data-iterator>
      </div>
    </v-container>
</template>

<script>
import moment from 'moment';
import 'moment/locale/nl';
import httpClient from '../../utils/HttpClient';
import config from '../../config';

moment.locale('nl');

const translations = {
  firstname: 'Voornaam',
  lastname: 'Achternaam',
  company: 'Bedrijf',
  added: 'Datum aangemaakt',
  lastmodifieddate: 'Datum laatste wijziging',
};

export default {
  name: 'ContactList',

  data: () => ({
    lists: [],
    list: undefined,
    contacts: [],
    contactselected: [],
    search: '',
    sortDesc: false,
    sortBy: 'firstname',
    keys: [],
    itemsPerPage: 9999,
    selected: -1,
  }),
  async mounted() {
    const { data: lists } = await httpClient.get('contactlists');
    lists.sort((a, b) => a.name.localeCompare(b.name));
    this.lists = lists;
  },
  methods: {
    async onChangeList(event) {
      this.list = event.listId;
      const { data: contacts } = await httpClient.get(`contactlists/${event.listId}/contacts`);
      this.contacts = contacts.map((obj) => {
        const contact = obj.properties;
        Object.keys(contact).map((key) => {
          if (contact[key] && contact[key].value !== undefined) {
            contact[key] = contact[key].value;
          }
          return key;
        });
        contact.id = obj.vid;
        contact.added = moment(obj.addedAt);
        contact.lastmodifieddate = moment(obj.lastmodifieddate);
        return contact;
      }).sort((a, b) => b.added.valueOf() - a.added.valueOf());
      if (this.contacts.length > 0) {
        const first = this.contacts[0];
        this.keys = Object.keys(first).filter((key) => key !== 'id' && key !== 'first_deal_created_date' && !key.includes('congres')).map((key) => ({ text: Object.prototype.hasOwnProperty.call(translations, key) ? translations[key] : key, value: key }));
      }
    },
    badges(contactId = undefined) {
      let qstring;
      if (this.contactselected.length > 0 && contactId === undefined) {
        qstring = `?contactid=${this.contactselected.join('&contactid=')}`;
      } else if (contactId !== undefined) {
        qstring = `?contactid=${contactId}`;
      }
      window.open(`${config.API_URL}contactlists/${this.list}/contacts/pdf/badges${qstring || ''}`, '_blank');
    },
    nameplates(contactId = undefined) {
      let qstring;
      if (this.contactselected.length > 0 && contactId === undefined) {
        qstring = `?contactid=${this.contactselected.join('&contactid=')}`;
      } else if (contactId !== undefined) {
        qstring = `?contactid=${contactId}`;
      }
      window.open(`${config.API_URL}contactlists/${this.list}/contacts/pdf/nameplates${qstring || ''}`, '_blank');
    },
    certificate(kind, contactId) {
      window.open(`${config.API_URL}contactlists/${this.list}/contacts/pdf/certificate?kind=${kind}&contactid=${contactId}`, '_blank');
    },
    selectRow(itemId) {
      this.selected = itemId;
    },
  },
};
</script>

<style lang="scss">
  .row {
    background: none;
    border-bottom: #ccc 1px solid;
  }
  .row:hover {
     background: #FAFAFA;
  }
  .rowSelected {
    background: #EEEEEE;
  }
  .v-list-item__title {
    text-align: left !important;
  }
  .v-messages {
    height: 0;
  }
</style>
