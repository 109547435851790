import axios from 'axios';
import config from '../config';

const httpClient = axios.create({
  baseURL: config.API_URL,
});
httpClient.defaults.headers.post.Accept = 'application/json';
httpClient.defaults.headers.get.Accept = 'application/json';
httpClient.defaults.withCredentials = true;

export default httpClient;
