<template>
  <div id="app" class="app">
   <div v-if="user.access_token">
      <router-view />
    </div>
   <div v-else class="d-flex justify-center">
   <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
    </div>
  </div>
</template>

<script>

import user from './utils/User';

export default {
  name: 'App',
  computed: {
    user() {
      return user.data;
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  min-height: 100%;
  padding: 0;
  margin: 0;
  text-align: center;
}
</style>
